// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    bets: {
      root:`${ROOTS.DASHBOARD}/bets`,
      casino:`${ROOTS.DASHBOARD}/bets/casino`,
      previous:`${ROOTS.DASHBOARD}/bets/previous`,
      detail:(betId:string) =>`${ROOTS.DASHBOARD}/bets/${betId}`
    },
    users: {
      root:`${ROOTS.DASHBOARD}/users`,
      CA_users: {
        root:`${ROOTS.DASHBOARD}/users/ca_users`,
        add:`${ROOTS.DASHBOARD}/users/ca_users/add`,
        edit:(admin_id:string) =>`${ROOTS.DASHBOARD}/users/ca_users/edit/${admin_id}`,
        detail:(admin_id:string) =>`${ROOTS.DASHBOARD}/users/ca_users/detail/${admin_id}`
      },
      clients:{
        root:`${ROOTS.DASHBOARD}/users/clients`,
        detail:(user_id:string) =>`${ROOTS.DASHBOARD}/users/clients/detail/${user_id}`
      },
      super_admins: {
        root: `${ROOTS.DASHBOARD}/users/super_admins`,
        listing:`${ROOTS.DASHBOARD}/users/super_admins/list`,
        add:`${ROOTS.DASHBOARD}/users/super_admins/add`,
        detail:(admin_id:string) =>`${ROOTS.DASHBOARD}/users/super_admins/detail/${admin_id}`,
        edit:(admin_id:string) =>`${ROOTS.DASHBOARD}/users/super_admins/edit/${admin_id}`,
        
      },
    },
    
    transfer:`${ROOTS.DASHBOARD}/transfer`,

    all_logs : {
      root:`${ROOTS.DASHBOARD}/all_logs`,
      session_logs:`${ROOTS.DASHBOARD}/all_logs/session`,
      multiple_ip_logs:`${ROOTS.DASHBOARD}/all_logs/multiple_ip`
    },

    casino_cms: `${ROOTS.DASHBOARD}/casino_cms`,
    
    notification: `${ROOTS.DASHBOARD}/site_notications`,

    settings:{
      root:`${ROOTS.DASHBOARD}/settings`,
      master_controller:`${ROOTS.DASHBOARD}/settings/master_controller`,
      templates:`${ROOTS.DASHBOARD}/settings/templates`,
      templates_banners:(template_id:string)=>`${ROOTS.DASHBOARD}/settings/template/${template_id}`,
    },
    developer:{
      root:`${ROOTS.DASHBOARD}/developer`,
      maintenance:`${ROOTS.DASHBOARD}/developer/maintenance`,
      dev_tools:`${ROOTS.DASHBOARD}/developer/dev_tools`,
    },
    sites:{
      list:`${ROOTS.DASHBOARD}/sites/list`,
    },

    surveillance:`${ROOTS.DASHBOARD}/surveillance`,
    maintenance: `${ROOTS.DASHBOARD}/maintenance`,
    sports: {
      root:`${ROOTS.DASHBOARD}/sports`,
      detail:(sportId:string,eventId: string) =>`${ROOTS.DASHBOARD}/sports/${sportId}/${eventId}`
    },
    casino: `${ROOTS.DASHBOARD}/casino`,
    
  },
};
