import axios, { AxiosRequestConfig } from 'axios';
// config
import { BE_HOST_API, HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

console.log(BE_HOST_API, HOST_API,"API");

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const axiosBEInstance = axios.create({ baseURL: BE_HOST_API });

axiosBEInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);


// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const fetcherBE = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosBEInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/admin-login',
    register:(user_id:string) =>`/api/auth/update-default-password/${user_id}`,
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  category:{
    list: 'api/menu',
    set_limit:(categoryId:string) => `api/menu/set-limit/${categoryId}`
  },
  sport:{
    list:'/api/game',
    listByCategory:(category_id:string)=>`/api/game/get-game-by-menu/${category_id}`,
    set_limit:(sportId:string)=>`api/game/set-limit/${sportId}`
  },
  league:{
    list:(game_id:string)=>`/api/league/get-league-by-game/${game_id}`,
    set_limit:(leagueId:string)=>`api/league/${leagueId}`,
    update_status:(leagueId:string)=>`api/league/league-status/${leagueId}`,
  },
  event:{
    // list:`/api/event/event-details`,
    list:`/api/event/event-list-by-game`,
    casino_list:`/api/event/casino-event-list-by-game`,
    set_limit:(game_event_id:string)=>`api/event/${game_event_id}`,
    setStatus:`api/event/event-status`,
    setIsPopular:`api/event/event-popular-status`,
    setIsBetLock:`api/event/event-bet-lock`,
    setIsStreamAvailable:`api/event/update-stream-score-status`,
    setIsScoreAvailable:`api/event/update-stream-score-status`,
    setIsLiveStream:`api/event/update-stream-score-status`,
    setIsLiveScore:`api/event/update-stream-score-status`,
    detail:(game_event_id:string)=>`api/event/${game_event_id}`,
    createCasinoEvent:`api/event`,
    updateCasinoEvent:(game_event_id:string)=>`api/event/update/${game_event_id}`,
    deleteCasinoEvent:(game_event_id:string,casino_provider_id:string)=>`api/event/delete/${game_event_id}?casino_provider_id=${casino_provider_id}`,
    changeDesktopVisibility:(game_event_id:string)=>`api/event/desktop/${game_event_id}`,
    changeMobileVisibility:(game_event_id:string)=>`api/event/mobile/${game_event_id}`,
    searchEvent :`/api/bet/event-search-list`,
    oddsByRunner :`/api/match-odds`,
  },
  casino:{
    casinoProvider:`api/casino-provider`,
    casinoProviderSubProvider:(parent_id:string|undefined) => `api/casino-provider/get-sub-category/${parent_id}`
  },
  market:{
    list:(game_event_id:string)=>`/api/event/get-market-by-eventId/${game_event_id}`,
    single_url:(market_id:string)=>`/api/game-market/get-single-market/${market_id}`,
    event_vise:`/api/game-market/getMarketList`,
    event_vise_param:(game_event_id:string)=>`/api/game-market/getMarketList/event_id=${game_event_id}`,
    set_limit:(game_event_id:string)=>`api/game-market/set-limit/${game_event_id}`,
    setStatus:(game_event_id:string)=>`api/game-market/market-status/${game_event_id}`,
    setIsBetLock:(game_event_id:string)=>`api/game-market/market-bet-lock/${game_event_id}`,
  },
  result:{
    declare:"api/result/declare",
    verify:"api/result/verify",
    rollback:"api/result/rollback",
  },
  user:{
    list: `api/user`,
    single_url:(user_id:string)=>`/api/user/get-single-user/${user_id}`,
    search : `api/user/search-user`,
    change_bet_lock:`api/user/user-bet-lock`,
    change_transfer_lock:`api/user/user-transfer-lock`,
    change_user_status:`api/user/change-user-status`
  },
  bet:{
    list:`/api/bet`,
    casino_list:`/api/bet/casino-bet-list`,
    get:(bet_id:string)=>`/api/bet/${bet_id}`,
    betTicker:`/api/bet/surveillance`,
    void:`/api/bet/void`
  },
  siteNotification:{
    get:`api/company-admin-notification/get-notification`,
    update:`api/company-admin-notification/`,
    status:`api/company-admin-notification/status`
  },
  superAdmins:{
    list:`api/super-admin/get-super-admins`,
    downlineList:(id:string) =>`api/super-admin/getDownLineHandler/${id}`,
    get:(admin_id:string) =>`api/super-admin/${admin_id}`,
    min_max_credit:(admin_id:string) =>`api/super-admin/min-max-credit-limits/${admin_id}`,
    edit:(admin_id:string) =>`api/super-admin/${admin_id}`,
    updateStatus:(admin_id:string) =>`api/super-admin/update-status/${admin_id}`,
    create:'api/super-admin'
  },
  transfer:{
    root:`api/super-admin/transfer`,
    list :`api/super-admin/transfer`,
    statement:(member_id:string)=>`api/transfer/transfer-statement/${member_id}`
  },
  maintenance:{
    get:`api/site-maintenance-notification`,
  },
  roles:{
    getPermission:`api/user-permission/`,
    get:`api/admin/get-company-admin`,
    getSingle:(admin_id:string)=>`api/admin/single-company-admin/${admin_id}`,
    getUserPermission:(admin_id:string) =>`api/user-permission/get-permission/${admin_id}`,
    updateStatus:(admin_id:string) =>`api/admin/update-status/${admin_id}`,
    create:`api/user-permission/`,
    updateUser:(admin_id:string) =>`api/user-permission/${admin_id}`,
  },
  logs:{
    activityLogByUser : (admin_id:string)=>`api/admin/activity-log/${admin_id}`,
    sessionLogs:`api/logs/session-logs`,
    multipleipLogs:`api/logs/ip-logs`
  },
  currency:{
    get:`api/currency`,
  },
  business:{
    get:`api/business`,
  },
  member :{
    breadcrumb:`api/super-admin/breadcrumb`,
    activityReport:(id:string)=>`api/profit-loss/activity-profit/${id}`,
    balance:(id:string) =>`api/super-admin/getBalance/${id}`,
    transferStatement:(id:string)=>`api/transfer/transfer-statement/${id}`,
    pnlStatement:`api/profit-loss/account-statement-pnl`,
    creditStatement:`api/profit-loss/credit-statement`,
    userStatement:`api/profit-loss/account-statement-user`,
    betList:`api/bet/bet-list`,
    netExposure : `api/exposure/admin-net-exposure-new`,
    betBreakdown : `api/exposure/bet-breakdown`,
    downlineExpo : `api/exposure/mymarketexpodownline-new`,
  },
  dev_tools:{
    positive_exposure:`api/dev-tools/positive-exposures/`,
    different_exposures:`api/dev-tools/different-exposures/`,
    different_runners:`api/dev-tools/different-runners/`,
    negative_users:`api/dev-tools/negative-balance/`,
    wrong_account_statment:`api/dev-tools/check-account-statement/`,
    fix_different_runners:`api/dev-tools/update-different-runners`,
    fix_account_statement:`api/dev-tools/account-statement`,
    fix_user_current_credit:`api/dev-tools/current-correct-credit`,
    fix_master_current_credit:`api/dev-tools/current-correct-credit-master`,
    fix_admin_current_credit:`api/dev-tools/current-correct-credit-admin`,
    rollback_result_to_pending:`api/dev-tools/declare-to-pending`,
  },
  settings:{
    master_controller:`api/settings/company-master-controller`,
    updateStatus:`api/settings/update-master-controller-game`,
    templates:`api/template`
  },
  site_cms:{
    list:`api/domain`
  },
  banner:{
    get:`api/site-banner`,
    add:`api/site-banner`,
    edit:`api/site-banner`,
    upload:`api/upload-banner-image`
  }
};